import React, { useState } from "react"
import { Link } from "gatsby"
import { data } from "./data"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"
import classNames from "classnames"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"
import { navigate } from "@reach/router"
import { isIpad } from "../../../../utils/general"
import NavigationShopBanner from "../concierge/navigationshop-banner"

import DownArrow from "../../../../assets/icons/wild/caret-down-white.svg"

const Nav = styled.nav`
  display: none;

  ${queries.medium`
    display: inline-block;
    vertical-align: top;
    position: static;
    width: 100%;
    height: auto;
    padding: 0;
    .navigation-logo {
      display: none;
    }
  `}
  //for debugging
    [aria-expanded='true'] {
    //border: 1px orange solid;
  }

  [aria-expanded="false"] {
    //border: 1px green solid;
  }
`
const SubItemWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`
const SubItem = styled(Box)`
  height: 100%;

  .subtext {
    margin: auto;
    max-width: 180px;
    @media (min-width: 1024px) {
      max-width: 240px;
    }
  }
`

const NavUl = styled.ul``

const ListItem = styled.li`
  ${queries.medium`
    position: relative;
    display: inline-block;

    > a span {
      transition: border-bottom .3s;
      border-bottom: 1px solid transparent;
    }
    &.has-subnav {
      padding: 29px 6px 27px;
       @media (min-width: 800px) {
          padding: 29px 12px 27px;
      }   
      &:hover {
        > a span {
          border-bottom: 1px solid #90A9A6;
          cursor: default;
        }
      }
    }
    &.has-link {
      padding: 0;
      a {
      padding: 29px 6px 27px;
       @media (min-width: 800px) {
          padding: 29px 12px 27px;
        }  
      }
      &:hover {
        > a span {
           border-bottom: 1px solid #90A9A6;
        }
      }
    }
    a,
    span {
      display: inline-block;
      font-size: 10px !important;
      line-height: 14px !important;
      border-bottom: 1px solid transparent;
    }
    &.full-dropdown-container {
      position: initial;
    }
    &.has-subnav {
      &:focus,
      &.hover {
        .arrow-down {
          transform: rotate(180deg);
          svg {
            transform: rotate(180deg);
          }
        }
        .dropdown {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  `}
  ${queries.large`
    &.has-subnav {
      padding: 31px 8px;
    }
    &.has-link {
      a {
        padding: 31px 12px;
      }
    }
    a,
    span {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  `}
    ${queries.xLarge`
    &.has-subnav {
      padding: 31px 24px;
    }
    &.has-link {
      a {
        padding: 31px 24px;
      }
    }
  `}
`
const InternalNavLink = styled.a`
  display: inline-block;

  &:hover {
    border-bottom: 1px solid #ffffff;
  }
`
const GatsbyNavLink = styled(Link)`
  display: inline-block;
`
const ArrowSection = styled.span`
  display: inline-block;
  width: 8px;
  margin-left: 6px;
  position: relative;
  top: -2px;

  .arrow-down {
    transition: transform ease-in-out 0.15s;
  }

  ${queries.large`
    display: inline-block;
  `}
`
const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  /* visibility: visible;
          opacity: 1; */
  position: absolute;
  top: 72px;
  background: transparent;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;

  left: 50%;
  width: 206px;
  transform: translateX(-50%);

  &.full-dropdown {
    left: 0;
    width: 100%;
    transform: none;
  }

  ${queries.large`
    top: 77px;
    padding-top: 4px;
  `}
  &.dropdown-shop-desktop {
    .row {
      max-width: 1333px;
      width: 100%;

      .box-left {
        .box-left-children {
          width: 100%;
        }
      }
    }
  }
`
const DropdownWrapper = styled(Flex)`
  flex-direction: row;
  //
  //@media (min-width: 900px) {
  //  width: calc(100% + 1px);
  //}
  //@media (min-width: 1024px) {
  //  width: calc(100% + 3px);
  //  transform: translate(-3px);
  //}
  //@media (min-width: 1360px) {
  //  width: 100%;
  //  transform: none;
  //}
`

const MainBox = styled(Box)`
  a:focus,
  a:hover {
    .name-section {
      h4 {
        color: ${theme.wild.color.men.primary.default} !important;
        text-decoration: underline;
      }
    }
  }

  img {
    width: 100%;
    margin: auto;

    &.dropdown-image {
      ${queries.medium`
       width: 207px;
       padding: 10.4px 0;
    `}
    }
  }

  .flex-row {
    box-shadow:
      0px 0px 20px rgba(0, 0, 0, 0.05),
      0px 24px 38px rgba(0, 0, 0, 0.03),
      0px 9px 46px rgba(0, 0, 0, 0.03),
      0px 11px 15px rgba(0, 0, 0, 0.03);
  }
`
const NavItem = styled.div`
  background: #464d52;
  //border: 1px red dotted;

  &.product-page {
    background: #fff;
  }

  &.nav-item-0 {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    a {
      margin: 8px 4px 16px 8px;
    }
  }

  &.nav-item-1 {
    a {
      margin: 8px 4px 16px 4px;
    }
  }
  &.nav-item-2 {
    a {
      margin: 8px 8px 16px 4px;
    }
  }

  height: 100%;
  position: relative;

  > a {
    padding: 8px 16px;
    height: calc(100% - 16px);
    border-radius: 4px;
    //border: 1px red dotted;
    background: ${theme.wild.color.men.base.secondary};
    transition: background-color 0.3s;

    &:hover {
      background-color: ${theme.wild.color.men.secondary.alt};
    }
  }
`

const TitleIconBox = styled(Box)`
  text-align: center;

  .icon-wrap {
    display: inline-block;
    vertical-align: center;
    margin-right: 8px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .title-box {
    margin: auto;
    max-width: 134px;
    min-height: 36px;
    @media (min-width: 1240px) {
      max-width: 100%;
    }
  }

  h4 {
    display: inline-block;
    margin: auto;
  }
`

if (typeof window !== `undefined`) {
  // Polyfill for IE9+
  if (!window.Element.prototype.matches)
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector
  if (!window.Element.prototype.closest)
    window.Element.prototype.closest = function (selector) {
      var el = this
      while (el) {
        if (el.matches(selector)) {
          return el
        }
        el = el.parentElement
      }
    }
}

const addActiveItemClass = (el) => {
  if (el && el.classList && el.classList.contains("has-subnav")) {
    el.classList.add("hover")
    handleExpand(el)
  }
}

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[...document.querySelectorAll("nav [aria-expanded]")].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const onFocus = (el) => {
  handleExpand(el)
}

const onBlur = (el) => {
  handleExpand(el)
}

const removeAllActiveItemClass = (e) => {
  const hover = document.getElementsByClassName("hover")
  let i = 0
  for (; i < hover.length; i++) {
    hover[i].classList.remove("hover")
  }
}

const mobileArrowClick = (e, item) => {
  const listItem = document.getElementById(item.name)
  removeAllActiveItemClass()
  addActiveItemClass(listItem)
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const trackExpanded = (text) => {
  segmentEvent("Navigation Expanded", {
    module_location: "navigation",
    text,
  })
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url) => {
  if (isIpad()) {
    navigate(url)
  }
}

const Navigation = ({ isProductPage }) => {
  const [, setHoveredItem] = useState(null)

  const navLinks = data.map((item, index) => {
    return (
      <React.Fragment key={`navLink-${index}`}>
        {!item.isMobileOnly && (
          <ListItem
            id={item.name}
            key={`navitem-${index}`}
            className={` ${!!item.children ? "has-subnav" : "has-link"}   ${
              item.name === "products" ? "full-dropdown-container" : ""
            } `}
            onMouseEnter={(e) => {
              trackExpanded(item.link.text)
              addActiveItemClass(e.currentTarget)
            }}
            onMouseLeave={(e) => removeActiveItemClass(e)}
            onClick={(e) => mobileArrowClick(e, item)}
            onFocus={(e) => onFocus(e.currentTarget)}
            onBlur={(e) => onBlur(e.currentTarget)}
            data-linkname={item.name}
          >
            {!!item.children ? (
              <>
                {/* we need href="" for ADA */}
                <InternalNavLink
                  href=""
                  role="button"
                  aria-expanded="false"
                  onClick={(e) => removeActiveItemClass(e, true)}
                >
                  <TextElementMen
                    element="span"
                    text={item.link.text}
                    className="mono--small text-master-base-default uppercase text-center"
                  />
                  <ArrowSection>
                    <DownArrow className="arrow-down" />
                  </ArrowSection>
                </InternalNavLink>
                <DropdownMenu
                  className={`dropdown ${
                    item.name === "products"
                      ? "full-dropdown dropdown-shop-desktop"
                      : ""
                  }`}
                >
                  <DropdownWrapper className="row">
                    <Box className={`box-left`}>
                      <MainBox>
                        {item.children && (
                          <Box className={`box-left-children`}>
                            <Flex
                              flexWrap="wrap"
                              flexDirection={"row"}
                              ml={"0"}
                              className={`flex-row`}
                            >
                              {item.children.map((subitem, idx) => {
                                const NavIcon = subitem?.icon
                                return (
                                  <Box
                                    width={"25%"}
                                    key={`nav-item-${idx}`}
                                    className="box-left-children__item"
                                    pt={"8px"}
                                    px={"0"}
                                    style={{ maxHeight: "373px" }}
                                  >
                                    <NavItem
                                      className={classNames(`nav-item-${idx}`, {
                                        "product-page": isProductPage,
                                      })}
                                      onTouchStart={() =>
                                        onTouchStart(subitem.url)
                                      }
                                      // onClick={()=>onTouchStart(subitem.url)}
                                    >
                                      <Link
                                        to={subitem.url}
                                        key={`subitem-${idx}`}
                                        onClick={() => {
                                          trackClicked(
                                            subitem.name,
                                            subitem.url
                                          )
                                        }}
                                      >
                                        <SubItemWrapper
                                          onMouseEnter={() =>
                                            setHoveredItem(subitem.name)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredItem(null)
                                          }
                                          onClick={removeActiveItemClass}
                                        >
                                          <SubItem>
                                            <Flex flexWrap="wrap">
                                              <TitleIconBox
                                                width={1}
                                                pt={"8px"}
                                              >
                                                {subitem?.icon && (
                                                  <div className="icon-wrap">
                                                    <img
                                                      src={NavIcon}
                                                      alt={``}
                                                    />
                                                  </div>
                                                )}
                                                <Flex
                                                  alignItems={"center"}
                                                  className={`title-box`}
                                                >
                                                  <TextElementMen
                                                    element="h4"
                                                    text={subitem.title}
                                                    className={
                                                      "title--small text-master-base-default uppercase text-center"
                                                    }
                                                  />
                                                </Flex>
                                              </TitleIconBox>
                                              <Box
                                                width={1}
                                                pt={[
                                                  "16px",
                                                  "16px",
                                                  "4px",
                                                  "16px",
                                                  "4px",
                                                ]}
                                                pb={[
                                                  "24px",
                                                  "24px",
                                                  "9px",
                                                  "24px",
                                                  "9px",
                                                ]}
                                              >
                                                <img
                                                  srcSet={`
                                                  ${subitem.image}&w=262,
                                                  ${subitem.image}&w=350 1.5x,
                                                  ${subitem.image}&w=524 2x,
                                                  ${subitem.image}&w=768 3x
                                                `}
                                                  src={`${subitem.image}&w=524`}
                                                  className={`dropdown-image ${subitem.safeName}`}
                                                  alt={subitem.safeName}
                                                />
                                              </Box>
                                              <Box
                                                flex={1}
                                                mt="auto"
                                                className={`subtext`}
                                              >
                                                {subitem.subtext ||
                                                subitem.subtextDesktop ? (
                                                  <TextElementMen
                                                    element="p"
                                                    text={
                                                      subitem.subtextDesktop
                                                        ? subitem.subtextDesktop
                                                        : subitem.subtext
                                                    }
                                                    className="body--medium text-master-base-default text-center"
                                                  />
                                                ) : null}
                                              </Box>
                                            </Flex>
                                          </SubItem>
                                        </SubItemWrapper>
                                      </Link>
                                    </NavItem>
                                  </Box>
                                )
                              })}
                              <NavigationShopBanner
                                trackClicked={trackClicked}
                              />
                            </Flex>
                          </Box>
                        )}
                      </MainBox>
                    </Box>
                  </DropdownWrapper>
                </DropdownMenu>
              </>
            ) : (
              <>
                {/* we need href="" for ADA */}
                <GatsbyNavLink
                  to={item.link.url}
                  onClick={() => trackClicked(item.name, item.link.url)}
                  role="button"
                >
                  <TextElementMen
                    element="span"
                    text={item.link.text}
                    className="mono--small text-master-base-default uppercase text-center"
                  />
                </GatsbyNavLink>
              </>
            )}
          </ListItem>
        )}
      </React.Fragment>
    )
  })

  return (
    <Nav className="navigation">
      <NavUl>{navLinks}</NavUl>
    </Nav>
  )
}

export default Navigation
